import React from 'react'
import { Create, Datagrid, Edit, EditButton, List, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin'

const filters = [
  <TextInput source="method_id" label="Method Id" alwaysOn />,
  <TextInput source="label" label="Label" alwaysOn />
]

const EvmMethodLabelList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="method_id" />
      <TextField source="label" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const EvmMethodLabelEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="method_id" />
      <TextInput source="label" />
    </SimpleForm>
  </Edit>
)

const EvmMethodLabelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="method_id" />
      <TextInput source="label" />
    </SimpleForm>
  </Create>
)

const EvmMethodLabelShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="method_id" />
      <TextField source="label" />
    </SimpleShowLayout>
  </Show>
)

export { EvmMethodLabelList, EvmMethodLabelEdit, EvmMethodLabelCreate, EvmMethodLabelShow }
