import restProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'

function httpClient(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const { accessToken } = JSON.parse(localStorage.getItem('auth') || '{}')
  options.headers.set('authorization', `Bearer ${accessToken}`)
  return fetchUtils.fetchJson(url, options)
}

function provider(apiUrl) {
  return restProvider(apiUrl, httpClient)
}

export default provider
