import React from 'react'
import CountryList from 'country-list'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'

const typeChoices = ['public', 'private', 'etf']
  .map(item => ({
    id: item,
    name: item,
  }))

const countryChoices = CountryList.getData()
  .map(item => ({
    id: item.code,
    name: item.name
  }))

const filters = [
  <TextInput source="uid" label="Uid" alwaysOn />,
  <TextInput source="name" label="Name" alwaysOn />,
  <TextInput source="country" label="Country" alwaysOn />,
  <TextInput source="type" label="Type" alwaysOn />,
]

const TreasuryEntityList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="uid" />
      <TextField source="name" />
      <TextField source="country" />
      <TextField source="type" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const TreasuryEntityEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="uid" />
        <TextInput source="name" />
        <SelectInput source="country" choices={countryChoices} />
        <SelectInput source="type" choices={typeChoices} />
      </SimpleForm>
    </Edit>
  )
}

const TreasuryEntityCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="uid" />
      <TextInput source="name" />
      <SelectInput source="country" choices={countryChoices} />
      <SelectInput source="type" choices={typeChoices} />
    </SimpleForm>
  </Create>
)

const TreasuryEntityShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="uid" />
      <TextField source="name" />
      <TextField source="country" />
      <TextField source="type" />
    </SimpleShowLayout>
  </Show>
)

export { TreasuryEntityList, TreasuryEntityEdit, TreasuryEntityCreate, TreasuryEntityShow }
