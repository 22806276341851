import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'

const filters = [
  <TextInput name="address" source="address" label="Address" alwaysOn />,
  <ReferenceInput name="coin_id" source="coin_id" reference="coins" alwaysOn>
    <AutocompleteInput name="coin_id" optionText="name" />
  </ReferenceInput>,
  <TextInput name="chain_uid" source="chain_uid" label="Chain" alwaysOn />,
  <TextInput name="type" source="type" label="Type" />
]

const PlatformList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="address" />
      <TextField source="decimals" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const PlatformEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="type" helperText="eip20,native,solana,etc" validate={required()} />
      <TextInput source="chain_uid" validate={required()} helperText="ethereum,binance-smart-chain,solana" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="symbol" />
      <TextInput source="address" />
      <TextInput source="decimals" />
    </SimpleForm>
  </Edit>
)

const PlatformCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="type" helperText="eip20,native,solana,etc" validate={required()} />
      <TextInput source="chain_uid" validate={required()} helperText="ethereum,binance-smart-chain,solana" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="symbol" />
      <TextInput source="address" />
      <TextInput source="decimals" />
    </SimpleForm>
  </Create>
)

const PlatformShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="chain_uid" />
      <TextField source="type" />
      <TextField source="symbol" />
      <TextField source="address" />
      <TextField source="decimals" />
    </SimpleShowLayout>
  </Show>
)

export { PlatformList, PlatformEdit, PlatformCreate, PlatformShow }
