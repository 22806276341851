import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'

const CoinCategoryList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Category" source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const CoinCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Category" source="category_id" reference="categories">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

const CoinCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Category" source="category_id" reference="categories">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

const CoinCategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Category" source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export { CoinCategoryList, CoinCategoryEdit, CoinCategoryCreate, CoinCategoryShow }
