import React from 'react'
import { Create, Datagrid, Edit, EditButton, List, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin'

const LanguageList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="code" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const LanguageEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Edit>
)

const LanguageCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Create>
)

const LanguageShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="code" />
    </SimpleShowLayout>
  </Show>
)

export { LanguageList, LanguageEdit, LanguageCreate, LanguageShow }
