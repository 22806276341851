import React from 'react'
import { JsonField, JsonInput } from './JsonView'
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List, ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin'

const CategoryList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="uid" />
      <TextField source="order" />
      <BooleanField source="enabled" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const CategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput disabled source="uid" />
      <TextInput source="order" />
      <BooleanInput source="enabled" />
      <JsonInput source="description" />
    </SimpleForm>
  </Edit>
)

const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="uid" />
      <TextInput source="order" />
      <BooleanInput source="enabled" />
      <JsonInput source="description" />
    </SimpleForm>
  </Create>
)

const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="uid" />
      <TextField source="order" />
      <BooleanField source="enabled" />
      <ReferenceManyField label="Coins" reference="coin_categories" target="category_id">
        <SingleFieldList>
          <ReferenceField label="Coin" source="coin_id" reference="coins">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <JsonField source="description" addLabel={true} />
    </SimpleShowLayout>
  </Show>
)

export { CategoryList, CategoryEdit, CategoryCreate, CategoryShow }
