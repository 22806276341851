import React from 'react'
import { JsonField, JsonInput } from './JsonView'
import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin'

const filters = [
  <ReferenceInput label="Coin" source="coin_id" reference="coins" alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="round" label="Round" />
]

const FundsInvestedList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <ArrayField source="funds">
        <SingleFieldList>
          <ReferenceField label="Fund" source="id" reference="funds">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <TextField source="amount" />
      <TextField source="round" />
      <TextField source="date" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const FundsInvestedEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <JsonInput source="funds" />
      <TextInput source="amount" />
      <TextInput source="round" />
      <TextInput source="date" />
    </SimpleForm>
  </Edit>
)

const FundsInvestedCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="amount" />
      <TextInput source="round" />
      <TextInput source="date" />
    </SimpleForm>
  </Create>
)

const FundsInvestedShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <JsonField source="funds" />
      <TextField source="amount" />
      <TextField source="round" />
      <TextField source="date" />
    </SimpleShowLayout>
  </Show>
)

export { FundsInvestedList, FundsInvestedEdit, FundsInvestedCreate, FundsInvestedShow }
