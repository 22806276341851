import React from 'react'
import {
  Create,
  Datagrid,
  DateTimeInput,
  Edit,
  EditButton,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'

const choices = [
  { id: 'coins', name: 'coins' },
  { id: 'platforms', name: 'platforms' },
  { id: 'categories', name: 'categories' }
]

const UpdateStateList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="date" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const UpdateStateEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput source="name" choices={choices} />
      <DateTimeInput source="date" />
    </SimpleForm>
  </Edit>
)

const UpdateStateCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="name" choices={choices} />
      <DateTimeInput source="date" />
    </SimpleForm>
  </Create>
)

const UpdateStateShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="date" />
    </SimpleShowLayout>
  </Show>
)

export { UpdateStateList, UpdateStateEdit, UpdateStateCreate, UpdateStateShow }
