import React from 'react'
import { Create, Datagrid, Edit, EditButton, List, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin'

const ExchangeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="uid" />
      <TextField source="name" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const ExchangeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="uid" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)

const ExchangeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="uid" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)

const ExchangeShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="uid" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
)

export { ExchangeList, ExchangeEdit, ExchangeCreate, ExchangeShow }
