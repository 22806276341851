import React from 'react'
import { JsonField as Field, JsonInput as Input } from 'react-admin-json-view'

const jsonOptions = {
  name: null,
  collapsed: true,
  enableClipboard: false,
  displayDataTypes: false
}

export const JsonField = props => (
  <Field
    {...props}
    reactJsonOptions={jsonOptions}
  />
)

export const JsonInput = props => (
  <Input{...props} reactJsonOptions={jsonOptions} />
)
