import React from 'react'
import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceField,
  ReferenceManyField,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin'
import { JsonField, JsonInput } from './JsonView'

const filters = [
  <TextInput source="uid" label="Uid" alwaysOn />,
  <TextInput source="code" label="Code" alwaysOn />,
  <TextInput source="coingecko_id" label="Coingecko Id" />,
  <TextInput source="name" label="Name" />
]

const CoinList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="uid" />
      <TextField source="code" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const CoinEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="uid" validate={required()} />
      <TextInput source="coingecko_id" />
      <TextInput source="code" validate={required()} />
      <JsonInput source="description" />
      <JsonInput source="security" />
      <JsonInput source="links" />
    </SimpleForm>
  </Edit>
)

const CoinCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="uid" validate={required()} />
      <TextInput source="coingecko_id" />
      <TextInput source="code" validate={required()} />
      <JsonInput source="description" />
      <JsonInput source="security" />
      <JsonInput source="links" />
    </SimpleForm>
  </Create>
)

const CoinShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="uid" />
      <TextField source="coingecko_id" />
      <TextField source="price" />
      <TextField source="code" />
      <JsonField source="description" addLabel={true} />
      <JsonField source="security" addLabel={true} />
      <JsonField source="links" addLabel={true} />
      <ReferenceManyField label="Categories" reference="coin_categories" target="coin_id">
        <SingleFieldList>
          <ReferenceField label="category" source="category_id" reference="categories">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField perPage={50} label="Platforms" reference="platforms" target="coin_id">
        <Datagrid>
          <TextField source="type" />
          <TextField source="address" />
          <TextField source="chain_uid" />
          <TextField source="decimals" />
          <TextField source="symbol" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export { CoinList, CoinEdit, CoinCreate, CoinShow }
