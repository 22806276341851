import React from 'react'
import { Admin, Resource } from 'react-admin'
import { CoinCreate, CoinEdit, CoinList, CoinShow } from './Coin'
import { ChainCreate, ChainEdit, ChainList, ChainShow } from './Chain'
import { LanguageCreate, LanguageEdit, LanguageList, LanguageShow } from './Language'
import { PlatformCreate, PlatformEdit, PlatformList, PlatformShow } from './Platform'
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from './Category'
import { CoinCategoryCreate, CoinCategoryEdit, CoinCategoryList, CoinCategoryShow } from './CoinCategory'
import { TreasuryEntityCreate, TreasuryEntityEdit, TreasuryEntityList, TreasuryEntityShow } from './TreasuryEntity'
import { FundsInvestedCreate, FundsInvestedEdit, FundsInvestedList, FundsInvestedShow } from './FundsInvested'
import { TreasuryCreate, TreasuryEdit, TreasuryList, TreasuryShow } from './Treasury'
import { FundCreate, FundEdit, FundList, FundShow } from './Fund'
import { ReportCreate, ReportEdit, ReportList, ReportShow } from './Report'
import { EvmMethodLabelCreate, EvmMethodLabelEdit, EvmMethodLabelList, EvmMethodLabelShow } from './EvmMethodLabel'
import { AddressLabelCreate, AddressLabelEdit, AddressLabelList, AddressLabelShow } from './AddressLabel'
import { ExchangeCreate, ExchangeEdit, ExchangeList, ExchangeShow } from './Exchange'
import { UpdateStateCreate, UpdateStateEdit, UpdateStateList, UpdateStateShow } from './UpdateState'

import authProvider from '../core/auth-provider'
import dataProvider from '../core/data-provider'

function App() {
  const api = 'https://admin.blocksdecoded.com/api'

  return (
    <Admin dataProvider={dataProvider(api)} authProvider={authProvider(api)}>
      <Resource name="coins" list={CoinList} edit={CoinEdit} show={CoinShow} create={CoinCreate} />
      <Resource name="chains" list={ChainList} edit={ChainEdit} show={ChainShow} create={ChainCreate} />
      <Resource name="languages" list={LanguageList} edit={LanguageEdit} show={LanguageShow} create={LanguageCreate} />
      <Resource name="platforms" list={PlatformList} edit={PlatformEdit} show={PlatformShow} create={PlatformCreate} />
      <Resource name="categories" list={CategoryList} edit={CategoryEdit} show={CategoryShow} create={CategoryCreate} />
      <Resource name="coin_categories" list={CoinCategoryList} edit={CoinCategoryEdit} show={CoinCategoryShow} create={CoinCategoryCreate} />
      <Resource name="treasury_entities" list={TreasuryEntityList} edit={TreasuryEntityEdit} show={TreasuryEntityShow} create={TreasuryEntityCreate} />
      <Resource name="treasuries" list={TreasuryList} edit={TreasuryEdit} show={TreasuryShow} create={TreasuryCreate} />
      <Resource name="funds" list={FundList} edit={FundEdit} show={FundShow} create={FundCreate} />
      <Resource name="funds_invested" list={FundsInvestedList} edit={FundsInvestedEdit} show={FundsInvestedShow} create={FundsInvestedCreate} />
      <Resource name="reports" list={ReportList} edit={ReportEdit} show={ReportShow} create={ReportCreate} />
      <Resource name="address_labels" list={AddressLabelList} edit={AddressLabelEdit} show={AddressLabelShow} create={AddressLabelCreate} />
      <Resource name="evm_method_labels" list={EvmMethodLabelList} edit={EvmMethodLabelEdit} show={EvmMethodLabelShow} create={EvmMethodLabelCreate} />
      <Resource name="exchanges" list={ExchangeList} edit={ExchangeEdit} show={ExchangeShow} create={ExchangeCreate} />
      <Resource name="update_states" list={UpdateStateList} edit={UpdateStateEdit} show={UpdateStateShow} create={UpdateStateCreate} />
    </Admin>
  )
}

export default App
