import React from 'react'
import { Create, Datagrid, Edit, EditButton, List, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin'

const filters = [
  <TextInput source="address" label="Address" alwaysOn />,
  <TextInput source="label" label="Label" alwaysOn />
]

const AddressLabelList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="address" />
      <TextField source="label" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const AddressLabelEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="address" />
      <TextInput source="label" />
    </SimpleForm>
  </Edit>
)

const AddressLabelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="address" />
      <TextInput source="label" />
    </SimpleForm>
  </Create>
)

const AddressLabelShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="address" />
      <TextField source="label" />
    </SimpleShowLayout>
  </Show>
)

export { AddressLabelList, AddressLabelEdit, AddressLabelCreate, AddressLabelShow }
