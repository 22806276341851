import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceField, ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin'

const filters = [
  <ReferenceInput label="Coin" source="coin_id" reference="coins" alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="title" label="Title" />,
  <TextInput source="author" label="Author" />
]

const ReportList = props => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="author" />
      <TextField source="date" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const ReportEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput source="author" />
      <TextInput source="url" />
      <TextInput source="date" />
      <TextInput source="body" />
    </SimpleForm>
  </Edit>
)

const ReportCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <ReferenceInput label="Coin" source="coin_id" reference="coins">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="author" />
      <TextInput source="url" />
      <TextInput source="date" />
      <TextInput source="body" />
    </SimpleForm>
  </Create>
)

const ReportShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coin_id" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="author" />
      <TextField source="url" />
      <TextField source="date" />
      <TextField source="body" />
    </SimpleShowLayout>
  </Show>
)

export { ReportList, ReportEdit, ReportCreate, ReportShow }
